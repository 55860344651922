// THIS FILE IS AUTOGENERATED

export interface Version {
  commit: string;
  version: string;
  app: string;
}

export const version = {
  app: "myqq",
  commit: "fcf0d5b8a91f77c69497f00ce232fc7b0bb35397",
  version: "1.104.1",
};
